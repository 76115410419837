import React from "react";
import { FilterSectionData } from "../types/filter-types";
import {
  GlobalDateFilters,
  GlobalFilters,
  GlobalRadioFilters,
} from "../types/filter-types";
import { DAY_PICKER, RADIO } from "./component-configs";
import SmogIcon from "../../assets/svgs/solid-smog.inline.svg";
import CalendarIcon from "../../assets/svgs/solid-calendar-day.inline.svg";
import TimelineIcon from "../../assets/svgs/solid-timeline.inline.svg";
import { getDateWithNoTime } from "../helpers/date-helpers";
import { IMPACT_CARBON, IMPACT_WATER } from "./task-impact-common";
import {
  USAGE_PERIOD_DAILY,
  USAGE_PERIOD_MONTHLY,
  USAGE_PERIOD_WEEKLY,
} from "./usage-period";

export const FILTER_PAGE_TYPE = "FILTER_PAGE";

export const FILTER_DATE_TYPE = "FILTER_DATE";

export const FILTER_ALL_IMPACT_CATEGORY = "ALL_IMPACT_CATEGORY";

export const DEFAULT_FILTERS: GlobalFilters = {
  day: getDateWithNoTime(),
  impactCategory: FILTER_ALL_IMPACT_CATEGORY,
  usagePeriod: USAGE_PERIOD_DAILY.key,
};

// TODO add more options as and when the parameters are supported
export const FILTER_IMPACT_MAPPER = new Map<string, string>([
  [FILTER_ALL_IMPACT_CATEGORY, "All"],
  [IMPACT_CARBON.key, IMPACT_CARBON.label],
  [IMPACT_WATER.key, IMPACT_WATER.label],
]);

export const IMPACT_FILTER_CONFIG: FilterSectionData<GlobalRadioFilters> = {
  filterType: FILTER_PAGE_TYPE,
  title: "Ecological impact",
  icon: (props) => <SmogIcon {...props} />,
  filterVariable: "impactCategory",
  inputType: RADIO,
  inputName: "impact-category",
  mapping: FILTER_IMPACT_MAPPER,
};

export const FILTER_USAGE_PERIOD_MAPPER = new Map<string, string>([
  [USAGE_PERIOD_DAILY.key, USAGE_PERIOD_DAILY.label],
  [USAGE_PERIOD_WEEKLY.key, USAGE_PERIOD_WEEKLY.label],
  [USAGE_PERIOD_MONTHLY.key, USAGE_PERIOD_MONTHLY.label],
]);

export const DAY_FILTER_CONFIG: FilterSectionData<{}, GlobalDateFilters> = {
  filterVariable: "day",
  icon: (props) => <CalendarIcon {...props} />,
  inputType: DAY_PICKER,
  title: "Choose date",
  filterType: FILTER_DATE_TYPE,
};

export const USAGE_PERIOD_FILTER_CONFIG: FilterSectionData<GlobalRadioFilters> =
  {
    filterType: FILTER_DATE_TYPE,
    title: "Ecological impact period",
    icon: (props) => <TimelineIcon {...props} />,
    filterVariable: "usagePeriod",
    inputType: RADIO,
    inputName: "usage-period",
    mapping: FILTER_USAGE_PERIOD_MAPPER,
  };
